export const BASE_URL = "https://api.beido.co/api/v1/";

export default {
  logIn: BASE_URL + "user/testLogin",
  logOut: BASE_URL + "user/logout",
  getState: BASE_URL + "user/get-stats",
  getAllUsers: BASE_URL + "user/",
  deleteUser: BASE_URL + "user/",
  getAllPreferences: BASE_URL + "preference/",
  deletePreference: BASE_URL + "preference/",
  updatePreference: BASE_URL + "preference",
  createPreference: BASE_URL + "preference/create",
  getAllLikes: BASE_URL + "like/",
  getAllPosts: BASE_URL + "post/search-posts",
  deletePost: BASE_URL + "post/",
  getStats: BASE_URL + "user/stats/653415a6de56bfcd53fe450d",
  getAllFeedbacks: BASE_URL + "feedback/getAll",
  getAllReportFeedbacks: BASE_URL + "report/getall",
  deleteFeedback: BASE_URL + "feedback/delete",
  adminPost: BASE_URL + "post/create/admin",
  userNotify: BASE_URL + "alert/send",
  notifyAllUser: BASE_URL + "alert/update/send",
};
