import React, { useState } from "react";
import { Image, Modal } from "antd";

const ContentModal = ({ content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <p style={{ fontWeight: "bold", cursor: "pointer" }} onClick={showModal}>
        View
      </p>
      <div>
        <Modal
          className="customModal"
          style={{ marginTop: "2rem", width: "auto", height: "auto" }}
          width={800}
          title="Post"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            {content[0]?.type === "image"
              ? content?.map((subitem) => (
                  <Image
                    width={"100%"}
                    height={"100%"}
                    src={subitem?.link}
                    alt="Post"
                  />
                ))
              : content?.map((item) => (
                  <video width={"100%"} height={"100%"} controls>
                    <source src={item?.link} />
                  </video>
                ))}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ContentModal;
