import React, { useEffect, useState } from "react";
import Input from "antd/es/input/Input";
import Loader from "../loader/loader";
import { Button, Row, Col, Typography } from "antd";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { GreenNotify } from "../../helper/helper";
import { useNavigate } from "react-router-dom";

const NewPreference = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [title, setTitle] = useState();
  const [type, setType] = useState();

  const getAllPreferences = () => {
    let getRes = (res) => {
      console.log("res of get response", res);
    };

    callApi(
      "GET",
      routes.getAllPreferences,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  // useEffect(() => {
  //   getAllPreferences();
  // }, []);

  const createPreference = () => {
    let body = { title: title, type: type };

    let getRes = (res) => {
      GreenNotify("Preference is created successfully");
      navigate("/preference");
    };

    callApi(
      "POST",
      routes.createPreference,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  return (
    <>
      <div style={{ marginBottom: "2rem", padding: "3rem" }}>
        <Loader loading={isloading} />
        <h2>Create Preference</h2>
        <Row gutter={216}>
          <Col span={8}>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Title
            </Typography.Title>
            <Input
              style={{
                marginTop: "1rem",
                height: "5rem",
                width: "50rem",
                fontSize: "1.8rem",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          </Col>
          <Col span={8}>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Type
            </Typography.Title>
            <Input
              style={{
                marginTop: "1rem",
                height: "5rem",
                width: "50rem",
                fontSize: "1.8rem",
              }}
              value={type}
              onChange={(e) => setType(e.target.value)}
              placeholder="Type"
            />
          </Col>
        </Row>
      </div>
      <Button
        type="primary"
        style={{ marginLeft: "3rem" }}
        onClick={createPreference}
      >
        Create
      </Button>
    </>
  );
};

export default NewPreference;
