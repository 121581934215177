import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, DatePicker, Pagination, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Loader from "../../components/loader/loader";
import { editIcon, homeIcon, prizeIcon, redTrash } from "../../assets";
import { callApi, callApiSearch } from "../../api/apiCaller";
import routes from "../../api/routes";
import { useNavigate } from "react-router-dom";
import { GreenNotify } from "../../helper/helper";
import ContentModal from "../../components/contentmodal/ContentModal";
import SearchComp from "../../components/searchCom/search";
import moment from "moment";
import PostDescription from "../../components/postDescription/postDescription";

const Posts = () => {
  const [isloading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [getServiceapi, setGetServiceApi] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleDateChange = (date, v) => {
    setSelectedDate(v);
    console.log("date", date, v);
  };

  const getAllPosts = () => {
    let getRes = (res) => {
      setPosts(res?.data?.data);
      console.log("get all post", res);
      setPagination({ ...pagination, total: res?.data?.totalAvailable });
    };

    callApi(
      "GET",
      selectedDate == null
        ? `${routes.getAllPosts}?search=${search}&page=${pagination.current}&limit=${pagination.pageSize}`
        : `${routes.getAllPosts}?search=${search}&page=${pagination.current}&date=${selectedDate}&limit=${pagination.pageSize}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        // setPosts(error.posts);
        console.log("error", error);
      }
    );
  };

  const deletePost = (id) => {
    let getRes = (res) => {
      console.log("ress of delete", res);
      GreenNotify("Post is deleted successfully");
      getAllPosts();
    };

    callApi(
      "DELETE",
      `${routes.deletePost}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllPosts();
  }, [pagination.current, pagination.pageSize, selectedDate]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "er",
      align: "center",
      className: "type-name-column-header",
      // sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      align: "center",
      className: "type-name-column-header",
      // sorter: (a, b) => a.lastname.localeCompare(b.lastname),
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
      // sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      className: "type-name-column-header",
      width: 200,
    },
    {
      title: "Text",
      dataIndex: "text",
      align: "center",
      className: "role-name-column-header",
    },
    {
      title: "Content",
      dataIndex: "content",
      align: "center",
      className: "role-name-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "role-name-column-header",
    },
  ];

  const data = posts?.map((item, index) => {
    return {
      key: index,
      text: (
        <div className="post-admin-align-x">
          <p>
            {item?.text.length > 68
              ? item?.text.substring(0, 70) + "..."
              : item?.text}
          </p>
          <p>
            {item?.text.length > 68 ? (
              <PostDescription content={item?.text} />
            ) : null}
          </p>
        </div>
      ),
      er: item?.creator?.firstName,
      lastname: item?.creator?.lastName,
      email: item?.creator?.email,
      date: moment(item?.createdAt).format("DD-MM-YYYY HH:mm"),
      content: (
        <>
          <ContentModal content={item?.content} />
        </>
      ),
      edit: (
        <div
          onClick={() => navigate("/editquote", { state: { item: item } })}
          style={{ cursor: "pointer" }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
      ),
      delete: (
        <div
          onClick={() => deletePost(item?._id)}
          style={{ cursor: "pointer" }}
        >
          <img src={redTrash} alt="red-trash" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Posts</h1>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <SearchComp
          placeHolder={"Search"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          icon={<SearchOutlined />}
          onClick={() => getAllPosts()}
          type="primary"
          style={{ height: "5rem", marginLeft: "1rem" }}
        >
          Search
        </Button>
        <div style={{ marginLeft: "3rem" }}>
          <DatePicker
            style={{ height: "6rem" }}
            size="large"
            placeholder="Search by Date"
            onChange={handleDateChange}
          />
        </div>
      </div>

      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={false}
          className="subscriptionapi-table"
          scroll={{
            x: "max-content",
            // y: 55 * 5,
          }}
        ></Table>

        <Pagination
          style={{ marginTop: "16px", textAlign: "center" }}
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) =>
            setPagination({ ...pagination, current: page, pageSize })
          }
        />
      </div>
    </div>
  );
};

export default Posts;
