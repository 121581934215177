import React, { useState } from "react";
import { Breadcrumb, Row, Col, Typography, Input, Button } from "antd";
import { homeIcon } from "../../assets";
import Loader from "../../components/loader/loader";
import { GreenNotify, RedNotify } from "../../helper/helper";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { useNavigate } from "react-router-dom";

const NotifiyAlluser = () => {
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [descriptiontxt, setDescription] = useState("");
  const disabled = title.length == 0 || descriptiontxt.length == 0;

  const notifiyUser = () => {
    let data = {
      title: title,
      text: descriptiontxt,
    };

    let getRes = (res) => {
      console.log("res of notify", res);
      if (res.status === 201 || res.status === 200) {
        GreenNotify(res.message);
        navigate("/");
      } else {
        RedNotify(res.message);
      }
    };

    callApi(
      "POST",
      routes.notifyAllUser,
      data,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Notification for all users</h1>
      </div>
      <Row gutter={216}>
        <Col span={8}>
          <Typography.Title level={5} style={{ marginTop: "2rem" }}>
            Title
          </Typography.Title>
          <Input
            style={{ marginTop: "1rem", height: "5rem" }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Notification"
          />
        </Col>
      </Row>
      <Row gutter={216}>
        <Col span={8}>
          <Typography.Title level={5} style={{ marginTop: "2rem" }}>
            Description
          </Typography.Title>
          <Input
            style={{ marginTop: "1rem", height: "5rem" }}
            value={descriptiontxt}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Notification"
          />
        </Col>
      </Row>
      <Button
        disabled={disabled}
        type="primary"
        style={{ marginTop: "2rem" }}
        onClick={notifiyUser}
      >
        Send
      </Button>
    </div>
  );
};

export default NotifiyAlluser;
