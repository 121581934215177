import React from "react";
import { useState } from "react";
import Input from "antd/es/input/Input";
import { Button, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { GreenNotify } from "../../helper/helper";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";

const EditPreference = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState(location?.state?.item?.title);
  const [type, setType] = useState(location?.state?.item?.type);
  const [isloading, setIsLoading] = useState(false);
  const id = location?.state?.item?._id;
  console.log(location?.state?.item?._id);

  const updatePreference = () => {
    let getRes = (res) => {
      console.log("res of updatePreference", res);
      navigate("/preference");
      GreenNotify("Preference is updated successfully");
    };

    let body = {
      title: title,
      type: type,
    };

    callApi(
      "PATCH",
      `${routes.updatePreference}/${id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  return (
    <div style={{ marginBottom: "2rem", padding: "3rem", width: "50rem" }}>
      <Loader loading={isloading} />
      <h2>Edit Preference</h2>
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Title
      </Typography.Title>
      <Input
        style={{ marginTop: "1rem", height: "5rem" }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
      />
      <Typography.Title level={5} style={{ marginTop: "2rem" }}>
        Type
      </Typography.Title>
      <Input
        style={{ marginTop: "1rem", height: "5rem" }}
        value={type}
        onChange={(e) => setType(e.target.value)}
        placeholder="Type"
      />
      <Button
        type="primary"
        style={{ marginTop: "2rem" }}
        onClick={updatePreference}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditPreference;
