import React, { useState, useEffect } from "react";
// import "./quotes.css";
import { Breadcrumb, Table, Image, Input, Button, Modal } from "antd";
import Loader from "../../components/loader/loader";
import { addIcon, editIcon, homeIcon, options, redTrash } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
// import ModalDescription from "../../components/modalDescription/modalDescription";
// import OptionModal from "../../components/optionModal/optionModal";
import { useNavigate } from "react-router-dom";
import { GreenNotify } from "../../helper/helper";
// import EditModal from "../../components/editModal/editModal";
// import EditModal from "../../components/editModal/editModal";
// import EditOp from "../../components/editop/EditOp";

const Preference = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [preference, setpreference] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false);
  const [getServiceapi, setGetServiceApi] = useState(false);
  const [popup, setPopup] = useState(false);

  const getAllPreferences = () => {
    let getRes = (res) => {
      console.log("res of get response", res);
      setpreference(res?.data?.data);
    };

    callApi(
      "GET",
      routes.getAllPreferences,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const deletePreference = (id) => {
    setGetServiceApi(false);
    let getRes = (res) => {
      console.log("res of deleteQuote", res);
      GreenNotify("Quote is deleted successfully");
      setGetServiceApi(true);
    };

    callApi(
      "DELETE",
      `${routes.deletePreference}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllPreferences();
  }, [getServiceapi]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      className: "role-name-column-header",
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
      className: "type-name-column-header",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = preference?.map((item, index) => {
    return {
      key: index,
      title: item?.title,
      type: item?.type,
      edit: (
        <div
          onClick={() => navigate("/editpreference", { state: { item: item } })}
          style={{ cursor: "pointer" }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
      ),
      delete: (
        <div
          onClick={() => deletePreference(item?._id)}
          style={{ cursor: "pointer" }}
        >
          <img src={redTrash} alt="red-trash" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Preference</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Preference</h1>
        <div
          onClick={() => navigate("/newpreference")}
          className="server-roles-add-btn"
          style={{ backgroundColor: "#0C3C9E" }}
        >
          <img src={addIcon} alt="" />
          <p>Add New Preference</p>
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          className="subscriptionapi-table"
        ></Table>
      </div>
    </div>
  );
};

export default Preference;
