import { toast } from "react-toastify";
import AWS from "aws-sdk";
var Buffer = require("buffer/").Buffer;

export const GreenNotify = (text) => {
  toast.success(text, {
    position: toast.POSITION.TOP_CENTER,
  });

  // toast.error("Error Notification !", {
  //   position: toast.POSITION.TOP_LEFT,
  // });

  // toast.warn("Warning Notification !", {
  //   position: toast.POSITION.BOTTOM_LEFT,
  // });

  // toast.info("Info Notification !", {
  //   position: toast.POSITION.BOTTOM_CENTER,
  // });

  // toast("Custom Style Notification with css class!", {
  //   position: toast.POSITION.BOTTOM_RIGHT,
  //   className: "foo-bar",
  // });
};

//-----Previous Key-------

// AWS.config.update({
//   accessKeyId: "AKIA5TIUMCOIKENVLXUC",
//   secretAccessKey: "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",
//   region: "eu-central-1",
// });

//-----------New S3 key---------

AWS.config.update({
  accessKeyId: "AKIA5TIUMCOIOEXWQGHN",
  secretAccessKey: "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",
  region: "us-east-1",
});

const S3 = new AWS.S3();

export const RedNotify = (text) => {
  toast.error(text, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const uploadOnS3 = (cb, loader) => (evt) => {
  const files = evt.target.files;
  const file = files[0];
  loader(true);
  uploadVideoOnS3(file)
    .then((url) => {
      loader(false);
      cb(url);
    })
    .catch((error) => console.log("error", error));
};

export const uploadImageOnS3 = (cb, loader) => (evt) => {
  const files = evt.target.files;
  const file = files[0];
  loader(true);
  uploadImgOnS3(file)
    .then((url) => {
      loader(false);
      cb(url);
      console.log("url", url);
    })
    .catch((error) => console.log("error", error));
};

const uploadVideoOnS3 = async (src) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const params = {
          Bucket: "beidobucket2",
          Key: `${10000 + Math.round(Math.random() * 10000)}.mp4`,
          Body: new Buffer(
            reader.result.replace(/^data:video\/\w+;base64,/, ""),
            "base64"
          ),
        };
        let res = await S3.upload(params).promise();
        console.log(res);
        return resolve(res.Location);
      };
      reader.onerror = (e) => console.log("OOPS!", e);
      reader.readAsDataURL(src);
    } catch (error) {
      console.error("Error uploading to S3:", error);
      reject(error);
    }
  });
};

const uploadImgOnS3 = async (src) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const params = {
          Bucket: "beidobucket2",
          Key: `${10000 + Math.round(Math.random() * 10000)}.jpeg`,
          Body: new Buffer(
            reader.result.replace(/^data:image\/\w+;base64,/, ""),
            "base64"
          ),
        };
        let res = await S3.upload(params).promise();
        console.log(res);
        return resolve(res.Location);
      };
      reader.onerror = (e) => console.log("OOPS!", e);
      reader.readAsDataURL(src);
    } catch (error) {
      console.error("Error uploading to S3:", error);
      reject(error);
    }
  });
};

export const upload = (cb, setIsLoading) => (evt) => {
  const files = evt.target.files;
  const file = files[0];

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MmU1YWQ5Y2ZjN2JlZjE3ZjFkOTkxNCIsImlhdCI6MTY4MTEyMDU0NCwiZXhwIjoxNjg4ODk2NTQ0fQ.MwVbniYhtKpSyleEJwCJ_z6GKP9wlg4JEszWOIbOTsU"
  );

  var formdata = new FormData();
  formdata.append("file", file);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  setIsLoading(true);
  fetch("https://api.beido.co/api/v1/user/upload", requestOptions)
    .then((response) => response.json())
    .then((data) => {
      const url = data.url;
      setIsLoading(false);
      cb(url);
      console.log("url", url);
    })
    .catch((error) => {
      setIsLoading(false);
      console.log("error", error);
    });
};
