import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Image, Pagination } from "antd";
import {
  crossIcon,
  feedbackIcon,
  homeIcon,
  redTrash,
  trueIcon,
  userIcon,
} from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment";

const FeedBack = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);

  const getAllFeedbacks = () => {
    let getRes = (res) => {
      //console.log("res of feedbacks", res);
      setFeedbacks(res?.data?.data);
    };

    callApi(
      "GET",
      routes.getAllFeedbacks,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const deleteFeedback = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      console.log("res of deleteFeedback", res);
      setUpdateApi(true);
    };

    callApi(
      "DELETE",
      `${routes.deleteFeedback}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllFeedbacks();
  }, [updateApi]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      className: "role-name-column-header",
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      className: "role-name-column-header",
      align: "center",
      width: 150,
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      className: "type-name-column-header",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      className: "role-name-column-header",
      align: "center",
      width: 150,
    },

    {
      title: "Image",
      dataIndex: "image",
      align: "center",
      className: "type-name-column-header",
      width: 150,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
      width: 150,
    },
  ];

  const data = feedbacks?.map((item, index) => {
    return {
      key: index,
      firstName: item?.creator?.firstName,
      lastName: item?.creator?.lastName,
      feedback: item?.feedback,
      date: moment(item?.createdAt).format("DD-MM-YYYY HH:mm"),
      image: (
        <div className="product-list-image">
          <Image width={50} src={item?.creator?.image} alt="profile-image" />
        </div>
      ),
      delete: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => deleteFeedback(item?._id)}
        >
          <img src={redTrash} alt="" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Feedbacks</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Feedbacks</h1>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={{ Pagination, pageSize: 5 }}
          className="ant-table-wrapper .ant-table-thead"
        ></Table>
      </div>
    </div>
  );
};

export default FeedBack;
