import React, { useState } from "react";
import { Image, Modal } from "antd";

const PostDescription = ({ content }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <p style={{ color: "#0c3c9e", cursor: "pointer" }} onClick={showModal}>
        See More
      </p>
      <div>
        <Modal
          className="customModal"
          style={{ marginTop: "2rem", width: "auto", height: "auto" }}
          width={800}
          title="Post description:"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <p>{content}</p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PostDescription;
