import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Breadcrumb } from "antd";
import {
  disputeIcon,
  feedbackIcon,
  homeIcon,
  prizeIcon,
  userIcon1,
} from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";

const Dashboard = () => {
  const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [users, setUsers] = useState(0);
  const [posts, setPosts] = useState(0);
  const [preferences, setPreferences] = useState(0);
  const [feedbacks, setFeedbacks] = useState(0);

  const stateArr = [
    {
      title: "Total Users",
      count: users,
      icon: userIcon1,
    },
    {
      title: "Total Posts",
      count: posts * 1,
      icon: prizeIcon,
    },
    {
      title: "Total Preference",
      count: preferences,
      icon: disputeIcon,
    },
    {
      title: "Total Feedbacks",
      count: feedbacks,
      icon: feedbackIcon,
    },
  ];

  const getUsers = () => {
    let getRes = (res) => {
      setUsers(res?.totalUsers);
      setPosts(res?.totalPosts);
      setPreferences(res?.totalPreferences);
      setFeedbacks(res?.totalFeedback);

      console.log("res of get stat", res);
      // setShowModal(false);
    };

    callApi("GET", routes.getState, null, setIsLoading, getRes, (error) => {
      setUsers(error);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-main-container">
        <div className="dashboard-state-container">
          {stateArr.map((item) => (
            <div className="dashboard-state-count-container">
              <div className="dashboard-state-icon">
                <img src={item.icon} alt="icon" />
              </div>
              <div className="dashboard-state">
                <h2>{item.title}</h2>
                <p>{item?.count}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="dashboard-pie-chart-container">
          <Clock size={120} value={value} />
          <p>
            Current time:{" "}
            <span style={{ color: "red", fontWeight: "700" }}>
              {moment(new Date()).format("DD, MMM, YYYY , HH:mm A")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
