import React from "react";
import "./search.css";
import { searchIcon } from "../../assets";

const SearchComp = ({ value, onChange, placeHolder }) => {
  return (
    <div className="search-container">
      <img className="search-icon-img" src={searchIcon} />
      <input
        className="search-input"
        value={value}
        onChange={onChange}
        type="text"
        name="text"
        id="search-icon"
        placeholder={placeHolder}
      />
    </div>
  );
};

export default SearchComp;
