import React, { useState, useRef } from "react";
import Input from "antd/es/input/Input";
import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Typography,
  Checkbox,
  Slider,
} from "antd";
import { Input as AntInput } from "antd";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Loader from "../../components/loader/loader";
import { homeIcon, notificationIcon, videoIcon } from "../../assets";

import "./styles.css";
import {
  GreenNotify,
  RedNotify,
  upload,
  uploadImageOnS3,
  uploadOnS3,
} from "../../helper/helper";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";

const GeneralPost = () => {
  const dummyImage =
    "https://novathreadbucket.s3.amazonaws.com/nova-app-1685176470232-dummy.PNG";
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);

  const [content, setContent] = useState([]);
  const [text, setText] = useState("");
  const fileInputImageRef = useRef(null);
  const fileInputVideoRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [restricted, setRestricted] = useState(false);
  const [video, setVideo] = useState(null);
  const [coordinates, setCoordinates] = useState([-11.779889, 8.46055]);
  const [radius, setRadius] = useState(4);
  const inputRef = useRef(null);
  const googleAPIKey = "AIzaSyBoWAQcoWkE03IyYYwEJb6q0mTdaEEUMUA";
  const uploadImage = (image) => {
    const imageContent = {
      link: image,
      type: "image",
    };
    setContent([imageContent]);
  };

  const uploadVideo = (videoUrl) => {
    const videoContent = {
      link: videoUrl,
      type: "video",
    };
    setVideo(videoUrl);
    setContent([videoContent]);
  };

  const pickImageFile = () => {
    fileInputImageRef.current.click();
  };

  const pickVideoFile = () => {
    fileInputVideoRef.current.click();
  };
  const onChange = (e) => {
    setRestricted(e.target.checked);
  };
  const onChangeS = (value) => {
    console.log("onChange: ", value);
  };
  const onChangeComplete = (value) => {
    console.log("onChangeComplete: ", value);
    setRadius(value);
  };
  const tooltipFormatter = (value) => `${value} Km`;
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        uploadImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePlaceSelected = (place) => {
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setCoordinates([lng, lat]);

      console.log("Latitude: ", lat);
      console.log("Longitude: ", lng);
    } else {
      console.log("No geometry available for this place");
    }
  };
  // const handleVideoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.type.startsWith("video")) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       uploadVideo(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const disableValue = text.length === 0 && !content.length && !video;

  const uploadPost = () => {
    let data = {
      content: content,
      text: text,
      restrictedArea: !restricted,
      restrictedRadius: radius,
      location: {
        type: "Point",
        coordinates: coordinates,
        address: "",
        description: "",
      },
      tags: ["#test"],
    };

    //console.log("body", data);

    let getRes = (res) => {
      if (res.status === 201) {
        GreenNotify(res.message);
        navigate("/");
      } else {
        RedNotify(res.message);
      }
    };

    callApi("POST", routes.adminPost, data, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  return (
    <>
      <div style={{ marginBottom: "2rem", padding: "3rem" }}>
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>General Post</Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1>Post</h1>
        </div>
        <h1 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          Enter Location
        </h1>
        <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Checkbox className="custom-checkbox" onChange={onChange}>
            For All User
          </Checkbox>
        </div>
        {!restricted && (
          <div>
            <Autocomplete
              style={{ width: "350px", padding: "5px", fontSize: "14px" }}
              ref={inputRef}
              apiKey={googleAPIKey}
              onPlaceSelected={handlePlaceSelected}
              options={{
                types: ["geocode", "establishment"],
                // componentRestrictions: { country },
              }}
              defaultValue="Sierra Leone"
            />
          </div>
        )}
        {!restricted && (
          <div>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Select Radius Range
            </Typography.Title>
            <Slider
              style={{
                width: "35rem",
              }}
              styles={{
                tracks: {
                  background: "#d42815",
                },
              }}
              defaultValue={4}
              onChange={onChangeS}
              onChangeComplete={onChangeComplete}
              handleColor="#0c3c9e"
              handleActiveOutlineColor="#0c3c9e"
              tooltip={{ formatter: tooltipFormatter }}
            />
          </div>
        )}

        <div
          className="options-container"
          style={{ display: "flex", gap: "2rem", marginTop: "2rem" }}
        >
          <div
            className={`option ${selectedOption === "image" ? "selected" : ""}`}
            onClick={() => setSelectedOption("image")}
          >
            <Button>Add Image</Button>
          </div>
          <div
            className={`option ${selectedOption === "video" ? "selected" : ""}`}
            onClick={() => setSelectedOption("video")}
          >
            <Button>Add Video</Button>
          </div>
        </div>
        {selectedOption === "image" && (
          <div className="image-upload-container">
            {/* <h1> Add Image</h1> */}
            <img
              onClick={pickImageFile}
              alt="image.jpeg"
              src={content.length === 0 ? dummyImage : content[0].link}
            />
            <input
              type="file"
              ref={fileInputImageRef}
              style={{ display: "none" }}
              onChange={uploadImageOnS3(
                (url) => uploadImage(url),
                setIsLoading
              )}
              accept="image/*"
            />
          </div>
        )}
        {selectedOption === "video" && (
          <div className="video-upload-container">
            {video ? (
              <video
                controls
                alt="video.jpeg"
                src={video}
                style={{ width: "25rem", height: "20rem" }}
              />
            ) : (
              <img
                onClick={pickVideoFile}
                alt="video-icon"
                src={videoIcon}
                style={{
                  cursor: "pointer",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            )}
            <input
              type="file"
              ref={fileInputVideoRef}
              style={{ display: "none" }}
              onChange={uploadOnS3((url) => uploadVideo(url), setIsLoading)}
              accept="video/*"
            />
          </div>
        )}
        <Row gutter={216}>
          <Col span={8}>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Text
            </Typography.Title>
            <Input
              style={{ marginTop: "1rem", height: "5rem" }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Notification"
            />
          </Col>
        </Row>
        <Button
          disabled={disableValue}
          type="primary"
          style={{ marginTop: "2rem" }}
          onClick={uploadPost}
        >
          Upload
        </Button>
      </div>
    </>
  );
};

export default GeneralPost;
