import React from "react";
import { Layout, Menu } from "antd";
import {
  disputeIcon,
  feedbackIcon,
  logOutIcon,
  notification,
  notificationIcon,
  prizeIcon,
  userIcon,
} from "../assets";
import "./layout.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Products from "../pages/products/products";
import { useDispatch } from "react-redux";
import { accessToken, refreshToken, userData } from "../redux/userDataSlice";
import AddNewService from "../pages/addNewService/addNewService";
import UpdateService from "../pages/updateService/updateService";
import ProductOrder from "../pages/productOrder/productOrder";
import ServiceOrder from "../pages/serviceOrder/serviceOrder";
import { callApi } from "../api/apiCaller";
import routes from "../api/routes";
import { useState } from "react";
import Loader from "../components/loader/loader";
import { GreenNotify, RedNotify } from "../helper/helper";
import Dashboard from "../pages/dashboard/dashboard";
import Quotes from "../pages/quotes/quotes";
import UserList from "../pages/userList/userList";
import Preference from "../pages/preference/Preference";
import EditPreference from "../pages/editpreference/EditPreference";
import NewPreference from "../components/newpreference/NewPreference";
import Like from "../pages/like/Likes";
import Posts from "../pages/posts/Posts";
import FeedBack from "../pages/feedBack/FeedBack";
import PushNotification from "../pages/pushNotification/PushNotification";
import GeneralPost from "../pages/generalPost/generalPost";
import ReportFeedBack from "../pages/reportFeedback/reportFeedback";
import NotifiyAlluser from "../pages/NotifiyAlluser/NotifiyAlluser";

const { Content, Sider } = Layout;
const LayoutDashboard = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userData(null));
    dispatch(accessToken(""));
    dispatch(refreshToken(""));
    //   let getRes = (res) => {
    //     if (res.status == 200) {
    //       GreenNotify(res.message);
    //       dispatch(userData(null));
    //       dispatch(accessToken(""));
    //       dispatch(refreshToken(""));
    //     } else {
    //       RedNotify(res.message);
    //     }
    //   };

    //   let body = {
    //     device: {
    //       id: localStorage.getItem("deviceId"),
    //       deviceToken: "xyz",
    //     },
    //   };

    //   callApi("POST", routes.logOut, body, setIsLoading, getRes, (error) => {
    //     console.log("error", error);
    // });
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Loader loading={isloading} />
      <Sider
        style={{ background: "#0C3C9E", fontSize: "2rem", fontWeight: "400" }}
        width={280}
      >
        <div
          onClick={() => navigate("/")}
          style={{
            paddingTop: "4rem",
            textAlign: "center",
            color: "#FFFFFF",
            fontSize: "2.3rem",
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          BEIDO
        </div>
        <Menu
          style={{ marginTop: "7rem" }}
          inlineCollapsed={true}
          theme="#FFFFFF"
          defaultSelectedKeys={["1"]}
          mode="inline"
        >
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/posts")}
            icon={<img className="icon" src={prizeIcon} alt="" />}
            key="95"
          >
            Posts
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/general-post")}
            icon={<img className="icon" src={prizeIcon} alt="" />}
            key="95"
          >
            General Post
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/user-list")}
            icon={<img className="icon" src={userIcon} alt="" />}
            key="108"
          >
            Users
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/notify-all-users")}
            icon={<img className="icon" src={notification} alt="" />}
            key="108"
          >
            All user notify
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/preference")}
            icon={<img className="icon" src={disputeIcon} alt="" />}
            key="108"
          >
            Preference
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/feedback")}
            icon={<img className="icon" src={feedbackIcon} alt="" />}
            key="95"
          >
            Feedback
          </Menu.Item>
          <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/report-feedback")}
            icon={<img className="icon" src={feedbackIcon} alt="" />}
            key="95"
          >
            Report Feedback
          </Menu.Item>
          {/* <Menu.Item
            style={{
              marginBottom: "2rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            onClick={() => navigate("/pushnotification")}
            icon={<img className="icon" src={notificationIcon} alt="" />}
            key="95"
          >
            Push Notification
          </Menu.Item> */}
          <Menu.Item
            style={{
              marginTop: "5rem",
              fontSize: "2rem",
              fontWeight: "400",
              color: "#FFFFFF",
            }}
            icon={<img className="icon1" src={logOutIcon} alt="" />}
            onClick={logOut}
            key="89"
          >
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            background: "#fff",
            paddingTop: "2rem",
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/quotes" element={<Quotes />}></Route>
            <Route path="/new-service" element={<AddNewService />}></Route>
            <Route path="/update-service" element={<UpdateService />}></Route>
            <Route path="/user-list" element={<UserList />}></Route>
            <Route path="/preference" element={<Preference />}></Route>
            <Route path="/editpreference" element={<EditPreference />}></Route>
            <Route path="/newpreference" element={<NewPreference />}></Route>
            <Route path="/like" element={<Like />}></Route>
            <Route path="/posts" element={<Posts />}></Route>
            <Route path="/feedback" element={<FeedBack />}></Route>
            <Route path="/general-post" element={<GeneralPost />}></Route>
            <Route path="/report-feedback" element={<ReportFeedBack />}></Route>
            <Route
              path="/notify-all-users"
              element={<NotifiyAlluser />}
            ></Route>
            <Route
              path="/pushnotification"
              element={<PushNotification />}
            ></Route>

            {/* <Route path="/dispute" element={<Dispute />}></Route>
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
            <Route path="/prizes" element={<Prizes />}></Route>
            <Route path="/coins" element={<Coins />}></Route>
            <Route path="/profile" element={<Profile />}></Route> */}

            {/* <Route
              path="/termsConditions"
              element={<TermsConditions />}
            ></Route> */}
            <Route
              path="/products-order-list"
              element={<ProductOrder />}
            ></Route>
            <Route
              path="/services-order-list"
              element={<ServiceOrder />}
            ></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;
