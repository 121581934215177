import React, { useState } from "react";
import Input from "antd/es/input/Input";
import { Breadcrumb, Button, Col, Row, Typography } from "antd";
import Loader from "../../components/loader/loader";
import { homeIcon, notificationIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { GreenNotify, RedNotify } from "../../helper/helper";
import { useLocation, useNavigate } from "react-router-dom";

const PushNotification = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;
  const email = location.state?.email;

  const [text, setText] = useState("");
  const notifySend = () => {
    let data = {
      text: text,
      userId: userId,
    };
    let getRes = (res) => {
      console.log("res of notify", res);
      if (res.status == 201 || res.status == 200) {
        GreenNotify(res.message);
        navigate("/");
      } else {
        RedNotify(res.message);
      }
      // GreenNotify("Post is deleted successfully");
      // setGetServiceApi(true);
    };

    callApi("POST", routes.userNotify, data, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <>
      <div style={{ marginBottom: "2rem", padding: "3rem" }}>
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Notifications</Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1>Push Notifications</h1>
        </div>
        <Row gutter={216}>
          <Col span={8}>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Alert to {email}
            </Typography.Title>
            <Input
              style={{ marginTop: "1rem", height: "5rem" }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Notification"
            />
          </Col>
          {/* <Col span={8}>
            <Typography.Title level={5} style={{ marginTop: "2rem" }}>
              Amount
            </Typography.Title>
            <Input
              style={{ marginTop: "1rem", height: "5rem" }}
              //  value={amount}
              //  onChange={(e) => setAmount(e.target.value)}
              placeholder="Amount"
            />
          </Col> */}
        </Row>
        <Button
          type="primary"
          style={{ marginTop: "2rem" }}
          onClick={notifySend}
          disabled={text.length == 0}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default PushNotification;
